import React, { useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
} from "@mui/material";
import { Button, Modal } from "../../components/commons";
import { Pagination, Search } from "../../components/commons/Table";
import { useStyles } from "../../helper/Theme";
import { Link } from "react-router-dom";

const Schemas = () => {
  const classes = useStyles();
  const [nationalID, setnationalID] = useState();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const data = [
    {
      name: "National ID",
      version: "1.3",
      RegistrationDate: "2022-01-23",
    },
    {
      name: "Student ID",
      version: "1.4",
      RegistrationDate: "2022-01-23",
    },
  ];

  return (
    <>
      <Stack flexDirection={"row"} gap={2} mb={2}>
        <Button buttonName="Add new Schema" color="gray" />
        <Search />
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>
                <Checkbox defaultChecked />
              </TableCell>
              <TableCell>Schema name</TableCell>
              <TableCell>Version #</TableCell>
              <TableCell>Registration date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  className="MuiReportTable-root"
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell>
                    <Checkbox defaultChecked />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.version}</TableCell>
                  <TableCell>{item.RegistrationDate}</TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-evenly"}
                    >
                      <Link>Details</Link>
                      <Link
                        onClick={() =>
                          setIsModalOpen({
                            open: true,
                            currentComponent: "issueCredentials",
                          })
                        }
                      >
                        Issue
                      </Link>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination />
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Schemas;
