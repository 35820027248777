import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Button } from "../../../components/commons";
import QRCode from "react-qr-code";
import CircularProgress from '@mui/material/CircularProgress';

const IssueCredentials = ({ handleClose, qrcodebody,isHandlingVerification,verificationMessage,verificationCheckComplete }) => {
    useState(false);
  return (
    <>
      {isHandlingVerification && (
        <div>
          <DialogContent>
            <Stack width={"500px"} height={"250px"}>
              <Grid
                container
                gap={2}
                maxWidth={{ sm: "100%" }}
                margin={"auto"}
                paddingY={1}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                  >
                    <p>Authenticating...</p>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
        </div>
      )}
      {verificationCheckComplete && (
        <div>
          <DialogContent>
            <Stack width={"500px"} height={"250px"}>
              <Grid
                container
                gap={2}
                maxWidth={{ sm: "100%" }}
                margin={"auto"}
                paddingY={1}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                  >
                    <p>{verificationMessage}</p>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
        </div>
      )}
      {qrcodebody && !isHandlingVerification && !verificationCheckComplete && (
        <>
          <DialogTitle
            sx={{ mb: 2, p: 2, fontSize: "25px !important" }}
          ></DialogTitle>
          <DialogContent>
            <Stack width={"500px"}>
              <Grid
                container
                gap={2}
                maxWidth={{ sm: "100%" }}
                margin={"auto"}
                paddingY={1}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                  >
                    Scan the QR code from your credential
                  </Typography>
                  <Typography
                    fontSize={"22px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                  >
                    wallet to prove access rights
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <QRCode value={JSON.stringify(qrcodebody)} />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
            <Button buttonName="Cancel" color="white" onClick={handleClose} />
          </DialogActions>
        </>
      )}

      {qrcodebody.body?.scope[0].query && (
        <p>Type: {qrcodebody.body?.scope[0].query.type}</p>
      )}

      {qrcodebody.body.message && <p>{qrcodebody.body.message}</p>}

      {qrcodebody.body.reason && <p>Reason: {qrcodebody.body.reason}</p>}
    </>
  );
};

export default IssueCredentials;
