import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Button, Modal } from '../../../components/commons'
import PolygonIDVerifier from '../modal/PolygonIDVerifier';

const VerifyCard = ({ cardData,sessionID,qrcodebody,isHandlingVerification,verificationCheckComplete,verificationMessage }) => {
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    
    return (
        <>
            <Box sx={{ background: '#f7f6f6', p: 5, height: '450px', width: '450px', borderRadius: '20px' }}>
                <Stack justifyContent={'space-between'} alignItems={'center'} height={'-webkit-fill-available'}>
                    <Typography fontSize={'28px'} fontWeight={'600'} textAlign={'center'}>{cardData.name}</Typography>
                    <img src={cardData.img} alt='' width={'220px'} className='verify_card_img' />
                    <PolygonIDVerifier 
                    qrcodebody={qrcodebody} 
                    cardData={cardData} 
                    sessionID={sessionID} 
                    isHandlingVerification = {isHandlingVerification}
                    verificationCheckComplete = {verificationCheckComplete}
                    verificationMessage = {verificationMessage}
                    />
                </Stack>
            </Box>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "" })}
            />
        </>
    )
}

export default VerifyCard