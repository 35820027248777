import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import VerifyCard from "./common/VerifyCard";
import { MdVerifiedUser } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import { io } from "socket.io-client";
import Alert from "@mui/material/Alert";
const verifydata = [
  {
    name: "Download Microsoft Office 365 for free",
    img: "https://mgarvis.files.wordpress.com/2021/01/office_logos.jpg",
    buttonName: "I'm a student",
  },
  {
    name: "Blook a blood donation appointment",
    img: "https://upload.wikimedia.org/wikipedia/en/thumb/f/fe/Saudi_Ministry_of_Health_Logo.svg/1200px-Saudi_Ministry_of_Health_Logo.svg.png",
    buttonName: "I'm above 18",
  },
];

const Verifier = () => {
  // serverUrl is localServerURL if not running in prod
  // Note: the verification callback will always come from the publicServerURL
  // const serverUrl = window.location.href.startsWith("https")
  //   ? process.env.REACT_APP_VERIFICATION_SERVER_PUBLIC_URL
  //   : process.env.REACT_APP_VERIFICATION_SERVER_LOCAL_HOST_URL;

  const serverUrl = "http://103.190.242.8:8080"

  const getQrCodeApi = (sessionId) =>
    serverUrl + `/api/get-auth-qr?sessionId=${sessionId}`;
  const getSTQrCodeApi = (sessionId) =>
    serverUrl + `/api/get-stauth-qr?sessionId=${sessionId}`;

  const socketA = io(serverUrl);
  const socketB = io(serverUrl);
  const [sessionIdA, setSessionIdA] = useState("");
  const [sessionIdB, setSessionIdB] = useState("");
  const [qrCodeDataA, setQrCodeDataA] = useState();
  const [qrCodeDataB, setQrCodeDataB] = useState();
  const [isHandlingVerification, setIsHandlingVerification] = useState(false);
  const [verificationCheckComplete, setVerificationCheckComplete] =
    useState(false);
  const [verificationMessage, setVerfificationMessage] = useState("");
  const [socketEventsA, setSocketEventsA] = useState([]);
  const [socketEventsB, setSocketEventsB] = useState([]);
  const [success, setsuccess] = useState(false);
  const [verified, setverified] = useState(false);

  // Socket Connection for KYC credential verification
  useEffect(() => {
    socketA.on("connect", () => {
      setSessionIdA(socketA.id);
      console.log("socketA id for user :", socketA.id);
      // only watch this session's events
      socketA.on(socketA.id, (arg) => {
        setSocketEventsA((socketEventsA) => [...socketEventsA, arg]);
      });
    });
  }, []);

  // Socket Connection for Studuent credential verification
  useEffect(() => {
    socketB.on("connect", () => {
      setSessionIdB(socketB.id);
      // only watch this session's events
      console.log("socketB id for user :", socketB.id);
      socketB.on(socketB.id, (arg) => {
        setSocketEventsB((socketEventsB) => [...socketEventsB, arg]);
      });
    });
  }, []);

  //Fetch qr code for KYC credential for
  useEffect(() => {
    const fetchQrCode = async () => {
      const response = await fetch(getQrCodeApi(sessionIdA));
      const data = await response.text();
      return JSON.parse(data);
    };

    if (sessionIdA) {
      fetchQrCode().then(setQrCodeDataA).catch(console.error);
    }
  }, [getQrCodeApi, sessionIdA]);

  //Fetch qr code for Student credential for
  useEffect(() => {
    const fetchQrCode = async () => {
      const response = await fetch(getSTQrCodeApi(sessionIdB));
      const data = await response.text();
      return JSON.parse(data);
    };

    if (sessionIdB) {
      fetchQrCode().then(setQrCodeDataB).catch(console.error);
    }
  }, [getSTQrCodeApi, sessionIdB]);

  // socket event side effects
  useEffect(() => {
    if (socketEventsA.length) {
      const currentSocketEvent = socketEventsA[socketEventsA.length - 1];
      if (currentSocketEvent.fn === "handleVerification") {
        if (currentSocketEvent.status === "IN_PROGRESS") {
          setIsHandlingVerification(true);
        } else {
          setIsHandlingVerification(false);
          setVerificationCheckComplete(true);
          if (currentSocketEvent.status === "DONE") {
            setVerfificationMessage("✅ Verified proof");
            <Alert severity="success">This is a success Alert.</Alert>;
            setTimeout(() => {
              setverified(true);
              setsuccess(true);
            }, "2000");
            socketA.close();
            socketB.close();
          } else {
            setVerfificationMessage("❌ Error verifying VC");
            <Alert severity="error">This is an error Alert.</Alert>;
          }
        }
      }
    }
  }, [socketEventsA]);

  useEffect(() => {
    if (socketEventsB.length) {
      const currentSocketEvent = socketEventsB[socketEventsB.length - 1];

      if (currentSocketEvent.fn === "handleSTVerification") {
        if (currentSocketEvent.status === "IN_PROGRESS") {
          setIsHandlingVerification(true);
        } else {
          setIsHandlingVerification(false);
          setVerificationCheckComplete(true);
          if (currentSocketEvent.status === "DONE") {
            setVerfificationMessage("✅ Verified proof");
            <Alert severity="success">This is a success Alert.</Alert>;
            setTimeout(() => {
              setverified(true);
              setsuccess(true);
            }, "2000");
            socketB.close();
            socketA.close();
          } else {
            setVerfificationMessage("❌ Error verifying VC");
            <Alert severity="error">This is an error Alert.</Alert>;
          }
        }
      }
    }
  }, [socketEventsB]);

  return (
    <Box height={"calc(100vh - 150px)"}>
      <Stack
        height={"100%"}
        flexDirection={"row"}
        gap={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!verified ? (
          <>
            {verifydata.map((data, i) => {
              return (
                <Box key={i}>
                  <VerifyCard
                    qrcodebody={i == 1 ? qrCodeDataA : qrCodeDataB}
                    // qrcodebody= {qrCodeDataA}
                    isHandlingVerification={isHandlingVerification}
                    verificationCheckComplete={verificationCheckComplete}
                    verificationMessage={verificationMessage}
                    cardData={data}
                    sessionID={i == 1 ? sessionIdA : sessionIdB}
                    // sessionID={sessionIdA}
                  />
                </Box>
              );
            })}
          </>
        ) : (
          <>
            {success && verified ? (
              <Stack
                justifyContent={"center"}
                gap={4}
                alignItems={"center"}
                height={"-webkit-fill-available"}
              >
                <MdVerifiedUser
                  style={{ fontSize: "100px", color: "#71ba52" }}
                />
                <Typography
                  fontSize={"28px"}
                  fontWeight={"600"}
                  textAlign={"center"}
                >
                  Your proof is <span style={{ color: "#71ba52" }}>valid</span>,
                  and you are eligible for the service{" "}
                </Typography>
              </Stack>
            ) : (
              <Stack
                justifyContent={"center"}
                gap={4}
                alignItems={"center"}
                height={"-webkit-fill-available"}
              >
                <BiSolidError style={{ fontSize: "100px", color: "#b71009" }} />
                <Typography
                  fontSize={"28px"}
                  fontWeight={"600"}
                  textAlign={"center"}
                >
                  Your proof is{" "}
                  <span style={{ color: "#b71009" }}>not valid</span>, and you
                  are not eligible for the service{" "}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Verifier;
