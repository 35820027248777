/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { MdVerifiedUser } from "../../../helper/Icons";
import { Button, Input, Select } from "../../../components/commons";
import { schemaList } from "../../../helper/Helper";

import { SI1 } from "../../../helper/Constant";
import NationalID from "./NationalID";
import { generateKYCCredential } from "./Web3Request";
import { generateQRCode } from "./Web3Request";

const IssueCredentials = ({ handleClose }) => {
  const [value, setValue] = useState("");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  var issuerserverurl =
    "https://4559-2401-4900-1f3e-25ba-ca07-5ab9-fac5-8fc4.ngrok-free.app";

  const [nationalID, setnationalID] = useState({
    name: "",
    id: "",
    dob: "",
    expirystudent: "",
  });

  const handleStep2Click = () => {
    setStep2(!step2);
    setStep1(false);
  };
  const handleStep3Click = async () => {
    // setStep3(true);
    // const datastring = nationalID.dob.replace(/-/g, "");
    // setnationalID({ ...nationalID, dob: datastring });
    // const expirystring = nationalID.expirystudent.replace(/-/g, "");
    // setnationalID({ ...nationalID, expirystudent: expirystring });
    // setStep2(false);
    if (value == "Student Card") {
      const response = await generateKYCCredential(
        issuerserverurl,
        "Shrey",
        7777,
        20010908
      );
      let qrid = JSON.parse(response);
      console.log("Credential create response id : ", qrid.id);
      const studentIDQrBody = await generateQRCode(issuerserverurl, qrid.id);
      console.log("QR code for credential of StduentID: ", studentIDQrBody);
    } else {
      const response = await generateKYCCredential(
        issuerserverurl,
        "Shrey",
        7777,
        20010908
      );
      let qrid = JSON.parse(response);
      console.log("Credential create response id : ", qrid.id);
      const studentIDQrBody = await generateQRCode(issuerserverurl, qrid.id);
      console.log("QR code for credential of StduentID: ", studentIDQrBody);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const data = [
    {
      jsoncontext:
        "https://ipfs.io/ipfs/QmcuhJ6Pitx6E8ArEzxfkzcgyTVih8zfyjJMzBQGLsS8yo",
      type: "KYCAgeCredential",
    },
    {
      jsoncontext:
        "https://ipfs.io/ipfs/QmNWfjUJyErBjRKRFP7nucwfWcc6zcbVnb4LsjfAzbuqij",
      type: "StudentID",
    },
  ];

  return (
    <>
      <DialogTitle sx={{ mb: 2, p: 2, fontSize: "25px !important" }}>
        {(step1 || step2) && "Issue New Credential"}
      </DialogTitle>
      <DialogContent>
        <Stack width={"600px"}>
          {step1 && (
            <Grid
              container
              gap={2}
              maxWidth={{ sm: "500px" }}
              margin={"auto"}
              paddingY={1}
            >
              <Grid item xs={12}>
                <Select
                  labelSelect="Schema"
                  selectHead="Select Schema"
                  value={value}
                  onChange={handleChange}
                  selectList={schemaList}
                />
              </Grid>
              <Grid item xs={12}>
                <Input labelinput="Upload" type="file" />
              </Grid>
            </Grid>
          )}
          {step2 && value == "National ID" ? (
            <NationalID data={nationalID} handledata={setnationalID} />
          ) : (
            <></>
          )}
          {step2 && value == "Student Card" ? (
            <Grid
              container
              gap={2}
              maxWidth={{ sm: "500px" }}
              margin={"auto"}
              paddingY={1}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={SI1} width={"300px"} alt="" />
              </Grid>
              <Grid item xs={12}>
                <Stack flexDirection={"row"} gap={2} mb={1}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={"600"}
                    sx={{ width: "140px" }}
                  >
                    Name :
                  </Typography>
                  {/* <Typography fontSize={'18px'} fontWeight={'400'}>Ahmad majid Alabduljabbar</Typography> */}
                  <Input variant="standard" />
                </Stack>
                <Stack flexDirection={"row"} gap={2} mb={1}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={"600"}
                    sx={{ width: "140px" }}
                  >
                    University Name :
                  </Typography>
                  <Input variant="standard" />
                </Stack>
                <Stack flexDirection={"row"} gap={2} mb={1}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={"600"}
                    sx={{ width: "140px" }}
                  >
                    Student No :
                  </Typography>
                  <Input variant="standard" type={"number"} />
                </Stack>
                <Stack flexDirection={"row"} gap={2} mb={1}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={"600"}
                    sx={{ width: "140px" }}
                  >
                    Degree :
                  </Typography>
                  <Input variant="standard" type={"number"} />
                </Stack>
                <Stack flexDirection={"row"} gap={2} mb={1}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={"600"}
                    sx={{ width: "140px" }}
                  >
                    Expiry Date :
                  </Typography>
                  <Input variant="standard" type={"date"} />
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {step3 && (
            <Grid
              container
              gap={2}
              maxWidth={{ sm: "100%" }}
              margin={"auto"}
              paddingY={1}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MdVerifiedUser
                  style={{ fontSize: "50px", color: "#71ba52" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontSize={"22px"}
                  fontWeight={"600"}
                  textAlign={"center"}
                >
                  A new credential has been issued
                </Typography>
                <Typography
                  fontSize={"22px"}
                  fontWeight={"600"}
                  textAlign={"center"}
                >
                  Scan the following QR code to add it to your wallet
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={
                    "https://aboutreact.com/wp-content/uploads/2018/08/qrcode.png"
                  }
                  width={"300px"}
                  alt=""
                />
              </Grid>
            </Grid>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        <Button buttonName="Cancel" color="white" onClick={handleClose} />
        {step1 && <Button buttonName="Next" onClick={handleStep2Click} />}
        {step2 && <Button buttonName="Issue" onClick={handleStep3Click} />}
      </DialogActions>
    </>
  );
};

export default IssueCredentials;
