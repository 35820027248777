import React, { useState } from 'react';
import { Box, Drawer } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import { useLocation } from 'react-router-dom';

export default function Layout({ children }) {
    const { window } = Window;
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const drawerWidth = 120;
    const drawerWidthsmall = 120;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ height: '100vh', width: '100vw' }}>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Box component="nav"
                    sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, transition: 'width 0.3s ease-in-out' }}>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', transition: 'width 0.3s ease-in-out', width: { xs: drawerWidthsmall, md: drawerWidth }, backgroundColor: location.pathname === '/verifier' ? 'secondary.main' : 'primary.main' },
                        }}
                    >
                        <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerWidthsmall={drawerWidthsmall} />
                    </Drawer>
                    <Drawer variant="permanent" sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', transition: 'width 0.3s ease-in-out', width: drawerWidth, backgroundColor: location.pathname === '/verifier' ? 'secondary.main' : 'primary.main' },
                    }}>
                        <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerWidthsmall={drawerWidthsmall} />
                    </Drawer>
                </Box>
                <Box component="main" sx={{ flexGrow: 1, overflow: 'auto', width: '-webkit-fill-available', maxWidth: '1200px', margin: '0 auto' }} >
                    <Box sx={{ justifyContent: 'space-between', alignItems: 'center', width: '-webkit-fill-available', backgroundColor: 'transparent', p: { xs: '10px', sm: '20px' } }}>
                        <Header handleDrawerToggle={handleDrawerToggle} />
                    </Box>
                    <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'transparent', p: { xs: '10px', sm: '20px' } }}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>

    );
}
