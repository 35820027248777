import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CgMenuLeft } from '../../helper/Icons';
import { SidebarList } from '../../helper/Helper';
import { useLocation } from 'react-router-dom';

const Header = ({ handleDrawerToggle }) => {
  const location = useLocation();

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '6px', sm: 1 }} justifyContent={'flex-start'}>
          <Stack sx={{ backgroundColor: 'white.main', display: { xs: 'flex', md: 'none' }, cursor: 'pointer', boxShadow: '0px 0px 5px 0px rgba(74, 88, 236, 0.20)', width: { xs: '35px', sm: '40px' }, height: { xs: '35px', sm: '40px' }, borderRadius: '8px' }} justifyContent={'center'} alignItems={'center'}>
            <CgMenuLeft className='menu_icon' style={{ color: '#000' }} onClick={handleDrawerToggle} />
          </Stack>
          {SidebarList.map((data, index) => {
            return (
              location.pathname === data.path && (
                <Box key={index}>
                  <Stack
                    key={index}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography fontSize={{ xs: '22px', sm: "35px" }} fontWeight={'800'} lineHeight={1}>
                      {data.name}
                    </Typography>
                  </Stack>
                </Box>
              )
            );
          })}
          {location.pathname === '/verifier' &&
            <Box>
              <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                <Typography fontSize={{ xs: '22px', sm: "35px" }} fontWeight={'800'} lineHeight={1}>
                  XYZ Verifier
                </Typography>
              </Stack>
            </Box>}
        </Stack>

      </Stack>
    </>
  )
}

export default Header