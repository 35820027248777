import axios from "axios";

export async function generateKYCCredential(serverUrl, Name, ID_number, DOB) {
  let data = JSON.stringify({
    credentialSchema:
      "https://ipfs.io/ipfs/QmcuhJ6Pitx6E8ArEzxfkzcgyTVih8zfyjJMzBQGLsS8yo",
    type: "KYCAgeCredential",
    credentialSubject: {
      id: "did:polygonid:polygon:mumbai:2qNJoq7SqubnrSJQrjrwzXJXYRgZ2dK7aW99EQjJ3N",
      Name: Name,
      ID_number: ID_number,
      DOB: DOB,
    },
    expiration: 99999999999,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic dXNlci1pc3N1ZXI6cGFzc3dvcmQtaXNzdWVy",
    },
  };
  console.log("Claim api called !!!!");
  const idofqrcode = await axios
    .post(
      `${serverUrl}/v1/did:polygonid:polygon:mumbai:2qCnckf1q9scE96Cb6N9BnT9Cwn41gAYDD5EEZiye4/claims`,
      data,
      config
    )
    .then((response) => {
      console.log(JSON.stringify(response.data));
      const data = JSON.stringify(response.data);
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  return idofqrcode;
}

export async function generateQRCode(serverUrl, id) {
  console.log("Server url", serverUrl, "id:", id);

  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Basic dXNlci1pc3N1ZXI6cGFzc3dvcmQtaXNzdWVy'
    }
  };
  const qrcodebody = await axios.get(`${serverUrl}/v1/did:polygonid:polygon:mumbai:2qCnckf1q9scE96Cb6N9BnT9Cwn41gAYDD5EEZiye4/claims/${id}/qrcode`,config)
  .then((response) => {
    console.log("Response QR CODE:",response);
  })
  .catch((error) => {
    console.log(error);
  });
  // const myHeaders = new Headers();
  // myHeaders.append(
  //   "Authorization",
  //   "Basic dXNlci1pc3N1ZXI6cGFzc3dvcmQtaXNzdWVy"
  // );
  // myHeaders.append(
  //   "Content-Type",
  //   "application/json"
  // );

  // const requestOptions = {
  //   method: "GET",
  //   headers: myHeaders,
  //   redirect: "follow",
  // };

  // const qrcodebody = await fetch(
  //   "https://4559-2401-4900-1f3e-25ba-ca07-5ab9-fac5-8fc4.ngrok-free.app/v1/did:polygonid:polygon:mumbai:2qCnckf1q9scE96Cb6N9BnT9Cwn41gAYDD5EEZiye4/claims/8095dc22-d562-11ee-8c3c-0242ac130005/qrcode",
  //   requestOptions
  // )
  //   .then((response) => response.text())
  //   .then((result) => console.log(result))
  //   .catch((error) => console.error(error));

  return qrcodebody;
}
