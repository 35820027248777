import React from 'react'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Typography,
  } from "@mui/material";
  import { NId } from "../../../helper/Constant";
  import { Button, Input, Select } from "../../../components/commons";

const NationalID = ({data, handledata}) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        handledata({ ...data, [name]: value });
    };

  return (
    ((
        <Grid
          container
          gap={2}
          maxWidth={{ sm: "500px" }}
          margin={"auto"}
          paddingY={1}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img src={NId} width={"300px"} alt="" />
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={"row"} gap={2} mb={1}>
              <Typography
                fontSize={"18px"}
                fontWeight={"600"}
                sx={{ width: "140px" }}
              >
                Name :
              </Typography>
              <Input variant="standard" name="name" value={data.name} onChange={handleChange} />
            </Stack>
            <Stack flexDirection={"row"} gap={2} mb={1}>
              <Typography
                fontSize={"18px"}
                fontWeight={"600"}
                sx={{ width: "140px" }}
              >
                ID number :
              </Typography>
              <Input variant="standard" type={"number"} name="id" value={data.id} onChange={handleChange} />
            </Stack>
            <Stack flexDirection={"row"} gap={2} mb={1}>
              <Typography
                fontSize={"18px"}
                fontWeight={"600"}
                sx={{ width: "140px" }}
              >
                Date of Birth :
              </Typography>
              <Input variant="standard" type={"date"} name="dob" value={data.dob} onChange={handleChange} />
            </Stack>
            <Stack flexDirection={"row"} gap={2} mb={1}>
              <Typography
                fontSize={"18px"}
                fontWeight={"600"}
                sx={{ width: "140px" }}
              >
                Expiry Date :
              </Typography>
              <Input variant="standard" type={"date"} name="expirystudent" value={data.expirystudent} onChange={handleChange} />
            </Stack>
          </Grid>
        </Grid>
      ))
  )
}

export default NationalID