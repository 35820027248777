import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { IssueCredentials } from '../../pages/schemas/modals';
import { QrScan } from '../../pages/verifier/modal';

export default function CommonModal({ handleClose, modalOpen, qrcodebody,isHandlingVerification,verificationCheckComplete,verificationMessage }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === 'issueCredentials' && (
        <IssueCredentials handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'qrScan' && (
        <QrScan 
        handleClose={handleClose} 
        qrcodebody={qrcodebody} 
        modalOpen={modalOpen}
        isHandlingVerification = {isHandlingVerification}
        verificationCheckComplete = {verificationCheckComplete}
        verificationMessage = {verificationMessage}
        />
      )}
    </Dialog>
  );
}
