import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { BgImage } from './Constant';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    boxShadow: 'unset !important',
    borderRadius: '0 !important',
    border: '1px solid #4A4A4A !important',
    background: '#fff',
  },
  tableContainer: {
    boxShadow: 'unset !important',
    borderRadius: '0 !important',
    border: '1px solid #e4e7ed !important',
    background: '#fff',
    // borderRadius: '10px !important',
    // backgroundColor: '#fff !important',
    // padding: '10px 20px',
    // boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25) !important',
    // Responsive styles
    [theme.breakpoints.down('sm')]: {
      padding: '8px 10px',
    },

  },
  mainContainer: {
    backgroundImage: `url(${BgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  }
}));



export const theme = createTheme({
  palette: {
    primary: {
      main: '#033076',
    },
    secondary: {
      main: '#4c7a34',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
    gray: {
      main: '#4A4A4A',
      secondary: '#2D2D2D',
      head: '#010425',
      para: '#292929',
      cardtext: '#858585',
    },
    border: {
      main: '#D4C3C4',
    },
    red: {
      main: '#FF5636'
    },
    green: {
      main: '#00AD6F'
    },
    lightprimary: {
      main: '#00BFF570',
    },
  },
  components: {
    // button -----------
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          textTransform: 'capitalize !important',
          fontSize: '18px !important',
          lineHeight: '1 !important',
          fontWeight: '500',
          color: '#fff',
          padding: '14px 20px',
          width: 'fit-content',
          minWidth: 'unset',
          whiteSpace: 'nowrap',
          boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '12px 14px',
          },
          '& .MuiButton-startIcon svg': {
            '@media (max-width: 600px)': {
              fontSize: '19px !important',
            },
          }
        },
        containedWhite: {
          color: '#00074E',
        },
        sizeSmall: {
          padding: '12px 15px',
          fontSize: '16px !important',
        },
        containedGreen: {
          padding: '10px 20px',
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '7px',
        }
      }
    },
    // button -----------
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            input: {
              fontSize: '18px',
              fontWeight: '400',
              borderRadius: '10px',
              padding: '12px 20px',
              color: '#000',
              background: '#fff',
              boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
              '@media (max-width: 600px)': {
                fontSize: '14px !important',
                padding: '12px 13px',
              },
            },
            fieldset: {
              // border: 'unset !important',
            }
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#747474',
          },
          '& .MuiInputBase-adornedStart ': {
            paddingLeft: '10px',
            input: {
              padding: '9px 14px 9px 0',
            },
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '5px',
        },
        root: {
          '& .MuiInputBase-input': {
            boxShadow: 'unset !important',
          }
        }
      }
    },
    // input ------------
    // select ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: '10px',
          fontSize: '18px',
          fontWeight: '400',
          padding: '13px 20px',
          minHeight: 'unset',
          color: '#000',
          background: '#fff',
          boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '10px 15px',
          },
          '& .MuiInputBase-root:hover': {
            // border: '1px solid #454545 !important',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000',
          },
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            minHeight: '35px !important',
          },
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: '99999'
        }
      }
    },
    // select ------------
    // table -----------
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              fontWeight: '700',
              textTransform: 'uppercase',
              color: '#000',
              background: '#e2e2e294'
            }
          },
          '& .MuiTableCell-root': {
            padding: '12px 15px',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            "&:first-child": { borderLeft: 0 },
            // color: '#4E4E4E'
          },
          // Media query for smaller screens
          '@media (max-width: 600px)': {
            '& .MuiTableCell-root': {
              fontSize: '14px',
              padding: '10px 10px',

            },
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
              '&:nth-of-type(even)': {
                background: '#f7f7f7', // Change to your desired background color for even rows
              },
            },
            '& .MuiTableRow-root:hover': {
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              background: '#03307610'
            },
          },
          '& .MuiReportTable-root': {
            '& .MuiTableCell-root': {
              textAlign: 'start !important',
              // '&:nth-of-type(2)': {
              //   textAlign: 'start !important',
              // },
              '&:first-of-type': {
                textAlign: 'start !important',
              },
              '&:last-child': {
                textAlign: 'start !important',
              },
            }
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          height: '28px',
          fontSize: '16px',
          '@media (max-width: 600px)': {
            height: '24px',
            fontSize: '14px',
          },
          '& .MuiSvgIcon-root ': {
            fontSize: '18px',
          }
        }
      }
    },
    // table -----------
    // modal -----------
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: '600',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          maxWidth: '100% !important'
        }
      }
    },
    // modal -----------
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: '40px',
            textTransform: 'capitalize',
            alignItems: 'flex-start'
          },
          '& .Mui-selected ': {
            background: '#eef4f6',
          },
          '& .MuiTabs-indicator': {
            left: 0,
            right: 'unset',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '20px !important'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: 0,
        }
      }
    },
  },
});

export const MyComponent = styled('div')({
  borderRadius: '0px',
  background: '#151515',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
});

export const getEducatorsStatus = (value) => {
  const status = {
    false: {
      title: "Absent",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "Present ",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};

export const getAlertStatus = (value) => {
  const status = {
    false: {
      title: "Off",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "On",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};