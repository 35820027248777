import React, { useState } from "react";
import { Button, Modal } from "../../../components/commons";
import CircularProgress from '@mui/material/CircularProgress';

const PolygonIDVerifier = ({ sessionID, cardData,qrcodebody,isHandlingVerification,verificationCheckComplete,verificationMessage }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  return (
    <div>
      {sessionID ? (
        <Button
          buttonName={cardData.buttonName}
          color="secondary"
          style={{ width: "100%" }}
          onClick={() =>
            setIsModalOpen({ open: true, currentComponent: "qrScan" })
          }
        />
      ) : (
        <CircularProgress />
      )}
      <Modal
        modalOpen={isModalOpen}
        qrcodebody ={qrcodebody}
        isHandlingVerification = {isHandlingVerification}
        verificationCheckComplete = {verificationCheckComplete}
        verificationMessage = {verificationMessage}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </div>
  );
};

export default PolygonIDVerifier;
