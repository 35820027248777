import { MdSchema, MdOutlineWallet, } from '../helper/Icons'

export const SidebarList = [
    {
        name: 'Schemas',
        icon: <MdSchema style={{ fontSize: '50px', color: '#fff' }} />,
        activeIcon: <MdSchema style={{ fontSize: '50px', color: '#033076' }} />,
        path: '/'
    },
    {
        name: 'Credentials',
        icon: <MdOutlineWallet style={{ fontSize: '50px', color: '#fff' }} />,
        activeIcon: <MdOutlineWallet style={{ fontSize: '50px', color: '#033076' }} />,
        path: '/credentials'
    },
]

export const schemaList = [
    { name: 'National ID', value: 'National ID' },
    { name: 'Student Card', value: 'Student Card' },
]
