import React, { useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelSelect, style, value, className, onChange, disabled } = props;



    return (
        <>
            {labelSelect && <Typography fontSize={{ xs: '12px', sm: '15px' }} fontWeight={500} mb={'2px'}>{labelSelect}</Typography>}
            <Select value={value} onChange={onChange} displayEmpty fullWidth sx={style} className={className} disabled={disabled}>
                {selectHead && <MenuItem value="" hidden disabled>{selectHead}</MenuItem>}
                {selectList.map((data, i) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)}
            </Select>
        </>
    );
}