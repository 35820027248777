import React from 'react'
import { Box, ListItem, ListItemButton, ListItemIcon, List, ListItemText, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarList } from '../../helper/Helper';


const Sidebar = ({ handleDrawerToggle, drawerWidth, drawerWidthsmall }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box width={{ xs: drawerWidthsmall, md: '100%' }} sx={{ transition: 'width 0.3s ease-in-out', height: '100vh' }}>
      <List sx={{ padding: '10px', textAlign: 'center' }}>
        <Typography fontSize={'45px'} fontWeight={'800'} color={'white.main'}>SEC</Typography>
      </List>
      {location.pathname === '/verifier' ? '' :
        <Stack justifyContent={'space-between'} height={'calc(100% - 110px)'}>
          <Box marginTop={3}>
            {SidebarList.map((data, index) => (
              <List sx={{ padding: '0' }} key={index}>
                <ListItem onClick={() => navigate(data.path)} key={data} disablePadding sx={{ margin: { xs: '10px 0', sm: '15px 0' }, width: 'unset' }}>
                  <ListItemButton onClick={handleDrawerToggle} key={index}
                    sx={{ display: 'flex', gap: '6px', flexDirection: 'column', padding: { xs: '9px 15px', sm: '13px 20px' }, backgroundColor: `${location.pathname === data.path ? 'white.main' : ''}`, }}
                    className={location.pathname === data.path ? 'active_sidebar' : ''}>
                    <ListItemIcon sx={{ transition: 'width 0.3s ease-in-out', justifyContent: 'center' }}>
                      {location.pathname === data.path ? data.activeIcon : data.icon}
                    </ListItemIcon>
                    <ListItemText primary={data.name} className='sidebar_text' sx={{
                      margin: '0', transition: 'width 0.3s ease-in-out', fontSize: '14px',
                      color: `${location.pathname === data.path ? 'primary.main' : 'white.main'}`
                    }} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Box>
        </Stack>}
    </Box>
  )
}

export default Sidebar