import { ThemeProvider } from '@mui/material';
import { Route, Routes, useLocation } from "react-router-dom";
import { theme } from './helper/Theme';
import ScrollToTop from './helper/ScrollToTop';
import Layout from './components/layouts/Layout';
import './App.css';
import PageNotFound from './helper/PageNotFound';
import Schemas from './pages/schemas';
import Credentials from './pages/credentials';
import Verifier from './pages/verifier';


const pages = [
  { name: 'Schemas', path: '', element: <Schemas /> },
  { name: 'Credentials', path: 'credentials', element: <Credentials /> },
  { name: 'Verifier', path: 'verifier', element: <Verifier /> },
]

function App() {
  const location = useLocation();
  const slug = location.pathname.split("/")[1];
  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <div className='background_Image'>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            {pages.map((page, index) => {
              const { name, path, element } = page;
              return (
                <Route key={index} exact path={`/${path}`} name={name} element={<Layout>{element}</Layout>} />
              );
            })}
          </Routes>
        </div>
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
